import { useNavigate, NavLink } from "react-router-dom";
import { Api } from "../../services/Api";
import { Button } from "@informed-innovations/components";
import PropTypes from "prop-types";

const LogInternalLinkProps = {
   to: PropTypes.string,
   children: PropTypes.node,
   primary: PropTypes.bool,
   outline: PropTypes.string,
   alternate: PropTypes.bool,
};

export const LogInternalLink = props => {
   const { to, children, primary, outline, alternate } = props;

   const api = new Api();
   api.setup();
   const navigate = useNavigate();

   const handleClick = async e => {
      e.preventDefault();
      const hashIndex = to.indexOf("#");
      const scrollElement = to.substring(hashIndex);
      const pathName = window.location.pathname.substring(1);
      const utm_source = pathName === "" ? "lockers-main" : pathName;
      const utm_campaign = to === "/" ? "lockers-main" : to.substring(1);
      const utm_show_id = Number(localStorage.getItem("show_id"));
      await api.postDynamicLogAnalytics(utm_source, "click", utm_campaign, utm_show_id);
      navigate(to);
      if (hashIndex !== -1) {
         setTimeout(() => {
            const element = document.querySelector(scrollElement);
            if (element) {
               element.scrollIntoView({ behavior: "smooth", block: "start" });
            }
         }, 0);
      }
   };

   if (alternate) {
      return (
         <NavLink to={to} onClick={handleClick}>
            {children}
         </NavLink>
      );
   } else {
      return primary ? (
         <Button primary outline={outline} href={to} onClick={handleClick}>
            {children}
         </Button>
      ) : (
         <Button secondary outline={outline} href={to} onClick={handleClick} style={{ textDecoration: "none" }}>
            {children}
         </Button>
      );
   }
};

LogInternalLink.propTypes = LogInternalLinkProps;

export default LogInternalLink;
